<template>
  <div class="min-h-screen text-white flex flex-col bg-purple-pattern">
    <Navigation/>
    <div v-if="!ethereum.connected">
      <div class="text-center text-2xl mt-20">
        Please connect your wallet to continue...
      </div>
      <div class="text-center mt-10 text-2xl">
        <ConnectButton/>
      </div>
    </div>
    <div v-if="loading" class="mt-10">
      <Spinner/>
    </div>
    <div :key="id" v-else>
      <div class="text-center font-pixel text-4xl mt-20">#{{ id.toString().padStart(5, '0') }}</div>
      <template v-if="!dead">
        <div style="width: 500px;" class="mx-auto my-5 p-5 rounded-md shadow-md bg-white">
          <ChainFace :id="id"/>
        </div>
        <div class="text-center text-xl cursor-pointer" @click="copy">
          <div>{{ face }}</div>
          <div class="text-sm">
            <template v-if="copied">
              Copied!
            </template>
            <template v-else>
              Click to copy
            </template>
          </div>
        </div>
        <div class="text-center mt-10">
          <a :href="'https://opensea.io/assets/0x93a796b1e846567fe3577af7b7bb89f71680173a/' + id" target="_blank">
            <img src="../assets/opensea-logo.png" style="width: 150px;" class="inline"/>
          </a>
        </div>
      </template>
      <div v-else>
        <div class="tombstone mx-auto mt-10">
          <div class="text">{{ face }}</div>
        </div>
        <div class="text-center text-3xl mt-10">
          <template v-if="!respectsPaid">
            Press <span class="text-yellow-500">F</span> to Pay Respects
          </template>
          <div class="respects" v-else>
            Goodnight, sweet prince.
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import { Ethereum } from '../js/ethereum';
import ChainFace from '../components/ChainFace';
import ConnectButton from '../components/ConnectButton';
import Modal from '../components/Modal';

const HAPPY_FACE_PLACE = '0x7039D65E346FDEEBbc72514D718C88699c74ba4b';

export default {
  name: 'Face',
  components: { Modal, ConnectButton, ChainFace, Spinner, Footer, Navigation },
  data () {
    return {
      loading: true,
      id: null,
      copied: false,
      dead: false,
      respectsPaid: false,
      face: '(-.-)',
      ethereum: Ethereum.state,
    };
  },
  watch: {
    '$route': {
      immediate: true,
      async handler () {
        this.loadFace();
      }
    }
  },
  methods: {
    async loadFace () {
      this.loading = true;

      if (!this.ethereum.connected) {
        setTimeout(this.loadFace.bind(this), 500);
        return;
      }

      this.id = parseInt(this.$route.params.id);

      const [face, owner] = await Promise.all([
        Ethereum.contract.assembleFace(this.id),
        Ethereum.contract.ownerOf(this.id)
      ]);

      this.face = face;
      this.dead = (owner === HAPPY_FACE_PLACE);

      this.loading = false;
    },
    copy () {
      navigator.clipboard.writeText(this.face);
      this.copied = true;
      setTimeout(() => {this.copied = false;}, 5000);
    }
  },
  created () {
    const component = this;
    this.handler = function (e) {
      if (e.key === 'f') {
        component.respectsPaid = true;
      }
    };
    window.addEventListener('keyup', this.handler);
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.handler);
  }
};
</script>

<style lang="scss">
.tombstone {
  background: url("../assets/tombstone.png");
  width: 512px;
  height: 512px;
  position: relative;

  .text {
    position: absolute;
    left: 160px;
    top: 200px;
    font-size: 45px;
    font-weight: bold;
    color: #292d33;
    text-align: center;
    width: 300px;
  }
}

.respects {
  animation: fadeout 3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}
</style>