<template>
  <div class="min-h-screen bg-purple-pattern text-white wallet" :class="{'arena-active': arenaOpen}">
    <Navigation/>
    <div class="container mx-auto px-4 py-10">
      <div v-if="!ethereum.connected || !ethereum.account">
        <div class="text-center text-2xl mt-10">
          Please connect your wallet to continue...
        </div>
        <div class="text-center mt-10 text-2xl">
          <ConnectButton/>
        </div>
      </div>
      <div v-else-if="loading">
        <Spinner/>
      </div>
      <div v-else-if="totalOwned === 0" class="text-center mt-5">
        <div class="mb-10 text-2xl font-pixel">
          You don't own any ChainFaces (yet)...
        </div>
        <button class="btn text-xl" @click="disconnectWallet">Disconnect Wallet</button>
      </div>
      <div v-else>
        <div class="flex flex-col md:flex-row justify-between items-center mb-10">
          <div class="text-3xl font-bold flex">
            <div class="mr-10 font-pixel text-yellow-200">
              Your Wallet
            </div>
            <button class="btn text-sm" @click="disconnectWallet">Disconnect</button>
          </div>
          <div class="text-left md:text-right">You own {{ totalOwned }} ChainFaces.</div>
        </div>
        <div class="bg-red-800 text-white p-4 text-lg mb-10 rounded-md shadow-md" v-if="arenaOpen">
          The arena is currently accepting <span class="line-through">sacrifices</span> noble warriors. You may select
          up to 20 ChainFaces at a time from your wallet below to volunteer them into the arena.
        </div>
        <div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5">
          <label v-for="id in this.faces" :key="id" :class="{'selected': selected[id]}"
                 class="bg-white p-2 rounded-md shadow-md cursor-pointer" :for="'face-' + id" @click="clickFace(id)">
            <ChainFace :id="id" class="border-gray-200 border"/>
            <div class="p-2 text-black text-sm">
              <div class="flex justify-between">
                <div>
                  <span v-if="arenaOpen" class="hidden">
                    <input type="checkbox" v-model="selected[id]" :id="'face-' + id" class="mr-1"/> Join Arena
                  </span>
                </div>
                <div class="font-pixel">#{{ id.toString().padStart(5, '0') }}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="fixed w-full bottom-0 text-center pb-5 arena-bar" :class="{shown: selectedCount > 0}">
      <button class="btn-arena text-2xl xl:text-4xl" :disabled="selectedCount > 20"
              @click="showWarning = true">
        <template v-if="selectedCount <= 20">
          Send {{ selectedCount }} Warrior/s to Arena
        </template>
        <template v-else>
          Select Max 20 Warriors
        </template>
      </button>
    </div>
  </div>
  <Modal v-if="showWarning" @close="showWarning = false">
    <div class="bg-white p-10">
      <div class="text-3xl font-bold text-red-500 font-pixel mb-5">
        WARNING:
      </div>
      <div class="mb-5 text-lg">
        If a ChainFace dies in the arena, it is lost forever. There is no way to retrieve a dead ChainFace and
        you will not be able to get a refund or sell a dead ChainFace on secondary markets. Seriously, it's gone.
      </div>
      <div class="mb-5">
        <input type="checkbox" v-model="agree" id="agree" class="mr-2">
        <label for="agree">No worries, I'm a total degen.</label>
      </div>
      <div class="grid grid-cols-2 gap-1 mt-10">
        <button class="btn-red text-2xl" @click="sendToArena" :disabled="!agree">
          Send 'em
        </button>
        <button class="btn" @click="showWarning = false">
          No, that's dumb
        </button>
      </div>
    </div>
  </Modal>
  <Footer/>
</template>

<script>
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import ConnectButton from '../components/ConnectButton';
import { Ethereum } from '../js/ethereum';
import ChainFace from '../components/ChainFace';
import Spinner from '../components/Spinner';
import { notifyError, notifyTransaction } from '../js/notifications';
import Modal from '../components/Modal';

export default {
  name: 'Wallet',
  components: { Modal, Spinner, ChainFace, ConnectButton, Footer, Navigation },
  data () {
    return {
      loading: true,
      arenaOpen: false,
      totalOwned: 0,
      faces: [],
      selected: {},
      showWarning: false,
      agree: false,
      ethereum: Ethereum.state,
    };
  },
  computed: {
    selectedCount () {
      let count = 0;
      for (let id in this.selected) {
        if (this.selected[id]) {
          count++;
        }
      }
      return count;
    }
  },
  watch: {
    'ethereum.account' () {
      this.getData(false);
    },
    'ethereum.chainId' () {
      this.getData(false);
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    async disconnectWallet () {
      Ethereum.disconnect();
    },
    clickFace (id) {
      if (!this.arenaOpen) {
        this.$router.push({ name: 'Face', params: { id: id } });
      }
    },
    async getData (loop = true) {
      if (this._isDestroyed) {
        return;
      }

      this.arenaOpen = await Ethereum.contract.entryOpen();
      this.totalOwned = (await Ethereum.contract.balanceOf(this.ethereum.account)).toNumber();

      let promises = [];
      for (let i = 0; i < this.totalOwned; i++) {
        promises.push(Ethereum.contract.tokenOfOwnerByIndex(this.ethereum.account, i).then(bn => bn.toNumber()));
      }

      this.faces = await Promise.all(promises);

      this.loading = false;

      if (loop) {
        setTimeout(this.getData.bind(this), 5000);
      }
    },
    async sendToArena () {
      this.agree = false;
      this.showWarning = false;

      let warriors = [];

      for (let id in this.selected) {
        if (this.selected[id]) {
          warriors.push(id);
        }
      }

      this.selected = [];

      try {
        let estimate = await Ethereum.contract.estimateGas.multiJoinArena(warriors);
        let gasLimit = estimate.mul('12').div('10');
        const tx = await Ethereum.contract.multiJoinArena(warriors, { gasLimit: gasLimit });
        notifyTransaction(tx, 'Join arena transaction sent.');
      } catch (e) {
        console.error(e.message);
        notifyError('Something went wrong.');
      }
    }
  }
};
</script>

<style lang="scss">
.wallet {
  &.arena-active {
    label {
      cursor: pointer;
      user-select: none;
      transition: all 0.1s ease-in-out;

      &.selected {
        transform: translateY(-10px);
        box-shadow: 0 0 10px rgb(255, 0, 0);
      }
    }
  }

  .arena-bar {
    bottom: -100px;
    transition: all 0.5s ease-in-out;

    &.shown {
      bottom: 0;
    }
  }
}
</style>
